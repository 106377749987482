import Input from '../components/Input'

const PasswordReset = () => {
  return (
    <div className='align-self-left'>
      <h2 className='section-header margin-bottom-16'>Password reset</h2>
      <div className='max-width-445 margin-bottom-40'>
        <p>
          A link to reset your password will be sent to your email. Go to your
          mail and follow the links
        </p>
      </div>
      <form>
        <Input
          label='Email'
          details={{
            placeholder: 'Enter your email',
            type: 'email',
            autoComplete: 'username',
            name: 'email',
          }}
        />
        <button className='button-large width-100'>Send</button>
      </form>
    </div>
  )
}

export default PasswordReset
