import Input from '../components/Input'

const CRA = ({ handleInput }) => {
  return (
    <>
      <Input
        label='Date of registered address change'
        details={{ type: 'date', name: 'change_date' }}
        onchange={handleInput}
      />
      <Input
        label='Changed company registered address line 1'
        details={{ type: 'text', name: 'reg_add_line1' }}
        onchange={handleInput}
      />
      <Input
        label='Changed company registered address line 2'
        details={{ type: 'text', name: 'reg_add_line2' }}
        onchange={handleInput}
      />
      <Input
        label='Changed company registered address postal code'
        details={{ type: 'text', name: 'reg_add_pc' }}
        onchange={handleInput}
      />
    </>
  )
}

export default CRA
