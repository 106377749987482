import Registration from '../components/Registration'
import { useRef } from 'react'
import Input from '../components/Input'
import { Link } from 'react-router-dom'

const Register = () => {
  const emailRef = useRef(null)
  const passwordRef = useRef(null)

  return (
    <>
      <h2 className='section-header margin-bottom-40'>Welcome to CorpFast</h2>
      <Registration>
        <form>
          <Input
            label='Full name'
            details={{
              placeholder: 'Enter your full name',
              type: 'text',
              name: 'full_name',
            }}
          />
          <Input
            label='Email'
            details={{
              placeholder: 'Enter your email',
              type: 'email',
              autoComplete: 'username',
              name: 'email',
            }}
            ref={emailRef}
          />
          <Input
            label='Company name'
            details={{
              placeholder: 'Enter company name',
              type: 'text',
              name: 'company_name',
            }}
          />
          <Input
            label='Password'
            details={{
              placeholder: 'Enter your password',
              name: 'password',
              type: 'password',
            }}
            ref={passwordRef}
          />
          <button className='button-large width-100 margin-top-32'>
            Sign up
          </button>
          <div className='flex-align-center gap-8 margin-top-8 self-center-align'>
            <span className='dark-gray-text'>or</span>
            <Link className='text-link' to='/login'>
              Log in
            </Link>
          </div>
        </form>
      </Registration>
    </>
  )
}

export default Register
