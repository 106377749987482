import Section from '../components/Section'
import { useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import { ReactComponent as Edit } from '../assets/edit.svg'

const Rates = () => {
  const demo = useMemo(
    () => [
      {
        id: 1,
        company_name: 'ABD Pte Ltd',
        company_uen: 'F9876123G',
        reg_add_line1: 'Jalan Sultan',
        reg_add_line2: 'Left Road',
        fye: '31/5/2022',
        status: 'AGM in 30 days',
        reg_add_pc: '567234',
        status_class: 'status-tag amber',
        directors: {
          1: 'David Thomas',
          2: 'Brian Hernandez',
          3: 'Geraldine Enos',
          4: 'Matthew Smith',
        },
      },
      {
        id: 2,
        company_name: 'Company B Pte Ltd',
        company_uen: 'L0946324J',
        reg_add_line1: 'Mohd Sultan',
        fye: '20/8/2020',
        reg_add_line2: 'Right Road',
        status: 'OK',
        status_class: 'status-tag',
        reg_add_pc: '739274',
        directors: {
          1: 'Cole Palmer',
          2: 'Erling Cruise',
          3: 'Paul Walker',
          4: 'Stewart Robert',
        },
      },
      {
        id: 3,
        company_name: 'Company C Pte Ltd',
        company_uen: 'L0946324J',
        reg_add_line1: 'Mohd Sultan',
        fye: '03/8/2024',
        reg_add_line2: 'Right Road',
        status: 'Immediate attention needed',
        status_class: 'status-tag red',
        reg_add_pc: '739274',
        directors: {
          1: 'Cole Palmer',
          2: 'Erling Cruise',
          3: 'Paul Walker',
          4: 'Stewart Robert',
        },
      },
    ],
    []
  )

  const standardRates = [
    {
      service: 'Annual corporate secretary fees',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
    },
    {
      service: 'Notice of Annual General Meeting',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
    },
    {
      service: 'Annual financial statements',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
    },
    {
      service: 'Shareholder resolution',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
    },
    {
      service: 'Registration of CORPPASS',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
    },
    {
      service: 'Change of registered address',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
    },
    {
      service: 'Change of company secretary',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
    },
  ]

  const [entities] = useState(demo)

  return (
    <Section backButtonEnabled={true}>
      <h2 className='section-header margin-bottom-8'>Your standard rates</h2>
      <div className='max-width-445 margin-bottom-32'>
        <p>
          These rates apply to all your clients. <br />
          If you wish to define specific rates, please visit your client’s page
          and choose “Fee Summary”
        </p>
      </div>
      <div className='max-width-940'>
        <div className='card no-bottom-radius padding-bottom-16'>
          <div className='flex-space-between align-center'>
            <h3 className='topic-header blue-text no-margin-bottom'>
              Your standard rates
            </h3>
            <button className='button-large' id='add_director'>
              + Add new rate
            </button>
          </div>
        </div>
        <table className='table margin-bottom-32'>
          <thead>
            <tr>
              <th className='table-checkbox'>Service</th>
              <th>Price</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {standardRates ? (
              standardRates.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className='table-checkbox'>{item.service}</td>
                    <td>{item.price}</td>
                    <td className='svg-blue'>
                      <Edit />
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={4}>No data available!</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className='card no-bottom-radius padding-bottom-16'>
          <div className='flex-space-between align-center'>
            <h3 className='topic-header blue-text no-margin-bottom'>
              Custom rates settings
            </h3>
          </div>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th className='table-checkbox'>Company name</th>
              <th>Company UEN</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {entities ? (
              entities.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className='table-checkbox'>{item.company_name}</td>
                    <td>{item.company_uen}</td>
                    <td className='svg-blue'>
                      <Link to='/custom-rates'><Edit /></Link>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={4}>No data available!</td>
              </tr>
            )}
          </tbody>
        </table>
        <div className='pagination-wrapper'>
          <div className='limit-wrapper'>
            <span className='dark-gray-text'>Showing</span>
            <select className='form-select'>
              <option>10</option>
              <option>20</option>
              <option>50</option>
              <option>100</option>
            </select>
            Out of 55
          </div>
          <div className='pagination-buttons'>
            <button>Prev</button>
            <button>01</button>
            <button>02</button>
            <button>03</button>
            <button>04</button>
            <button>05</button>
            <button>Next</button>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Rates
