import { forwardRef } from 'react'

const Input = forwardRef((props, ref) => {
  return (
    <div className='margin-bottom-20m width-100'>
      <label>{props.label}</label>
      <input {...props.details} ref={ref} onChange={props.onchange} className="input" />
    </div>
  )
})

export default Input
