import { useContext, useState } from 'react'
import { Outlet, Navigate, NavLink } from 'react-router-dom'
import { AuthContext } from '../store/authContext'
import { ReactComponent as Logout } from '../assets/logout.svg'
import { ReactComponent as Home } from '../assets/home_icon.svg'
import { ReactComponent as Document } from '../assets/document_icon.svg'
import { ReactComponent as ToggleNav } from '../assets/toggle_navigation.svg'
import { ReactComponent as Cog } from '../assets/cog.svg'
import { ReactComponent as Wallet } from '../assets/wallet.svg'

const ProtectedRoutes = () => {
  const { user, logout } = useContext(AuthContext)
  const [navOpen, setNavOpen] = useState(false)

  const toggleNavigation = () => {
    setNavOpen(prev => {
      return !prev
    })
  }

  const sideNavigationClass = navOpen ? 'side-navigation open' : 'side-navigation'
  

  console.log('protected route')

  if (!user) {
    return <Navigate to={'/login'} />
  }

  return (
    <>
      <nav className='blue-bg'>
        <div className='navbar-wrapper'>
          <div className='max-width-300'>BAMBOOSEA</div>
          <div className='credit-wrapper'>
            <div className='credit-display'>
              Available credits: <span className='credit-text'>1,500</span>
            </div>
            <button>+ Top up</button>
          </div>
          <button className='logout-button' onClick={logout}>
            <Logout />
            Log out
          </button>
        </div>
      </nav>
      <div className={sideNavigationClass}>
        <div>
          <div className='side-logo'>CorpFast</div>
          <div className='side-navigation-wrapper'>
            <div className='toggle-navigation' onClick={toggleNavigation}>
              <ToggleNav />
            </div>
            <NavLink className='nav-link' to={'/'}>
              <div className='nav-icon'>
                <Home />
              </div>
              Home
            </NavLink>
            <NavLink className='nav-link' to={'/rates'}>
              <div className='nav-icon'>
                <Wallet />
              </div>
              Standard rates
            </NavLink>
            <NavLink className='nav-link' to={'/invoices'}>
              <div className='nav-icon'>
                <Document />
              </div>
              Invoices
            </NavLink>
          </div>
        </div>
        <NavLink className='nav-link' to={'/settings'}>
          <div className='nav-icon'>
            <Cog />
          </div>
          Settings
        </NavLink>
      </div>
      <Outlet />
    </>
  )
}

export default ProtectedRoutes
