import { ReactComponent as BackArrow } from '../assets/back_arrow.svg'
import { Link } from 'react-router-dom'

const Section = (props) => {
  const isBackButton = props.backButtonEnabled || false
  const backButtonConfig = props.backButtonConfig || {to: '/'}
  const backButtonText = props.backButtonText || 'Back to Dashboard'
  return (
    <section>
      <div className='section-wrapper left-align'>
        {isBackButton && (
          <Link {...backButtonConfig} className='back-link margin-bottom-16'>
            <BackArrow />
            {backButtonText}
          </Link>
        )}
        {props.children}
      </div>
    </section>
  )
}

export default Section
