export const formatDate = (date) => {
  const newDate = new Date(date)
  const yyyy = newDate.getFullYear()
  let mm = newDate.getMonth() + 1
  let dd = newDate.getDate()

  if (dd < 10) dd = '0' + dd
  if (mm < 10) mm = '0' + mm

  const formattedDate = dd + '/' + mm + '/' + yyyy
  return formattedDate
}

export const convertTime = (time) => {
  const timeSplit = time.split(':')
  let hours = timeSplit[0]
  const AmOrPm = hours >= 12 ? 'PM' : 'AM'
  hours = hours % 12 || 12
  const minutes = timeSplit[1]
  const finalTime = hours + ':' + minutes + ' ' + AmOrPm

  return finalTime
}
