import Input from '../components/Input'
import { useRef, useState, useContext } from 'react'
import { AuthContext } from '../store/authContext'
import { Link } from 'react-router-dom'
import Registration from '../components/Registration'
import { ReactComponent as Eye } from '../assets/eye.svg'

const Login = () => {
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const [error, setError] = useState(null)
  const { login } = useContext(AuthContext)

  const handleForm = (e) => {
    e.preventDefault()
    setError(null)
    fetch('/api/login', {
      method: 'POST',
      body: JSON.stringify({
        email: emailRef.current.value,
        password: passwordRef.current.value,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => result.json())
      .then((data) => {
        if (data.error) {
          setError(data.error)
          return
        }
        login(data.token)
      })
  }

  return (
    <>
      <h2 className='section-header margin-bottom-40'>Welcome back</h2>
      <Registration>
        <form onSubmit={handleForm}>
          {error && <p className='error margin-bottom-40'>{error}</p>}
          <Input
            label='Email'
            details={{
              placeholder: 'Enter your email',
              type: 'email',
              autoComplete: 'username',
              name: 'email',
            }}
            ref={emailRef}
          />
          <div className='relative-wrapper'>
            <Input
              label='Password'
              details={{
                placeholder: 'Enter your password',
                name: 'password',
                type: 'password',
              }}
              ref={passwordRef}
            />
            <div
              style={{
                display: 'none',
                position: 'absolute',
                right: '.5rem',
                backgroundColor: 'white',
                padding: '0 .5rem 0 .5rem',
                bottom: '2.1rem',
                zIndex: 1,
              }}
            >
              <Eye />
            </div>
          </div>

          <Link to='/reset-password' className='text-link align-self-left'>
            Forgot your password?
          </Link>
          <button className='button-large width-100 margin-top-40'>
            Log in
          </button>
          <div className='flex-align-center gap-8 margin-top-8 self-center-align'>
            <span className='dark-gray-text'>or</span>
            <Link className='text-link' to='/signup'>
              Sign up
            </Link>
          </div>
        </form>
      </Registration>
    </>
  )
}

export default Login
