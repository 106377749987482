import { useEffect, useState, useMemo } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Section from '../components/Section'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'

const Index = () => {
  const navigate = useNavigate()
  const demo = useMemo(
    () => [
      {
        id: 1,
        company_name: 'ABD Pte Ltd',
        company_uen: 'F9876123G',
        reg_add_line1: 'Jalan Sultan',
        reg_add_line2: 'Left Road',
        fye: '31/5/2022',
        status: 'AGM in 30 days',
        reg_add_pc: '567234',
        status_class: 'status-tag amber',
        directors: {
          1: 'David Thomas',
          2: 'Brian Hernandez',
          3: 'Geraldine Enos',
          4: 'Matthew Smith',
        },
      },
      {
        id: 2,
        company_name: 'Company B Pte Ltd',
        company_uen: 'L0946324J',
        reg_add_line1: 'Mohd Sultan',
        fye: '20/8/2020',
        reg_add_line2: 'Right Road',
        status: 'OK',
        status_class: 'status-tag',
        reg_add_pc: '739274',
        directors: {
          1: 'Cole Palmer',
          2: 'Erling Cruise',
          3: 'Paul Walker',
          4: 'Stewart Robert',
        },
      },
      {
        id: 3,
        company_name: 'Company C Pte Ltd',
        company_uen: 'L0946324J',
        reg_add_line1: 'Mohd Sultan',
        fye: '03/8/2024',
        reg_add_line2: 'Right Road',
        status: 'Immediate attention needed',
        status_class: 'status-tag red',
        reg_add_pc: '739274',
        directors: {
          1: 'Cole Palmer',
          2: 'Erling Cruise',
          3: 'Paul Walker',
          4: 'Stewart Robert',
        },
      },
    ],
    []
  )
  const [entities] = useState(demo)
  const [selection, setSelection] = useState()
  const [searchTerm, setSearchTerm] = useState(null)

  useEffect(() => {
    demo.forEach((item) => {
      setSelection((prev) => {
        return { ...prev, [item.company_uen]: false }
      })
    })
  }, [demo])

  console.log(selection)

  const selectAllCheckboxes = (event) => {
    for (let key in selection) {
      setSelection((prev) => {
        return { ...prev, [key]: event.target.checked }
      })
    }
  }

  const handleCheckBox = (event) => {
    setSelection((prev) => {
      return { ...prev, [event.target.name]: event.target.checked }
    })
  }

  const handleSubmission = () => {
    const acceptable = []
    for (let key in selection) {
      if (selection[key]) {
        acceptable.push(key)
      }
    }

    if (!acceptable.length) {
      return false
    }

    const allSelectedEntities = entities.filter((entity) => {
      if (acceptable.includes(entity.company_uen)) {
        return entity
      }
      return null
    })

    navigate('/resolutions/notice-of-annual-general-meeting', {
      state: allSelectedEntities,
    })

    console.log(allSelectedEntities)
  }

  const handleOnSelect = (result) => {
    setSearchTerm(result)
  }

  return (
    <Section>
      <div className='flex-space-between margin-bottom-48'>
        <div className='max-width-445'>
          <h2 className='section-header'>
            <span className='black-text'>Welcome back,</span> Terence Seah
          </h2>
        </div>
        <div className='alert alert-primary'>
          <div className='max-width-340'>
            <h3 className='topic-header blue-text'>Notifications & Messages</h3>
            There are currently 20 companies with their AGM/AR due in the next
            month!
          </div>
          <button className='outline-button blue align-self-bottom'>
            Batch Process
          </button>
        </div>
      </div>
      <div className='grid columns-3 margin-bottom-48'>
        <div className='card'>
          <div className='grid columns-2-1'>
            <h3 className='topic-header'>Recently Generated Documents</h3>
            <div className='topic-header royal-blue-text justify-self-right'>
              24
            </div>
            <Link className='text-link justify-self-left'>View all files</Link>
          </div>
        </div>
        <div className='card'>
          <div className='grid columns-2-1'>
            <h3 className='topic-header'>Recently Signed Documents</h3>
            <div className='topic-header royal-blue-text justify-self-right'>
              24
            </div>
            <Link className='text-link justify-self-left'>View all files</Link>
          </div>
        </div>
        <div className='card'>
          <div className='grid columns-2-1'>
            <h3 className='topic-header'>Invoices</h3>
            <div className='topic-header royal-blue-text justify-self-right'>
              24
            </div>
            <div className='flex-vertical-start gap-8'>
              <Link className='text-link dark-text'>Outstanding</Link>
              <Link className='text-link dark-text'>Overdue</Link>
              <Link className='text-link dark-text'>Paid</Link>
            </div>
            <div className='spacer'></div>
            <Link to='/invoices' className='text-link justify-self-left'>
              View and download invoices
            </Link>
          </div>
        </div>
      </div>

      <div className='card flex-space-between no-bottom-radius align-center'>
        <h3 className='topic-header blue-text no-margin-bottom'>
          My Client List
        </h3>
        <div className='flex-align-center light-blue-text gap-8'>
          <Link className='blue-text'>Generate documents</Link>
        </div>
          <ReactSearchAutocomplete
            items={entities}
            showIcon={false}
            placeholder='Quick search'
            styling={{
              height: '3.5rem',
              border: '0px',
              borderRadius: '.5rem',
              backgroundColor: '#F0F6FE',
              boxShadow: 'none',
              hoverBackgroundColor: '#ACB0B7',
              color: '#212121',
              fontSize: '16px',
              fontFamily: 'inherit',
              iconColor: '#133B7F',
              lineColor: '#133B7F',
              placeholderColor: '#ACB0B7',
              clearIconMargin: '3px 14px 0 0',
              searchIconMargin: '0 0 0 16px',
            }}
            fuseOptions={{
              keys: [
                'company_name',
                'company_uen',
                'reg_add_line1',
                'reg_add_line2',
                'reg_add_pc',
              ],
            }}
            onSelect={handleOnSelect}
            resultStringKeyName='company_name'
            className='search-box'
          />
          <Link to='/entity/new' className='button button-large'>
            + Add new client
          </Link>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th className='table-checkbox'>
              <input
                type='checkbox'
                className='form-check-input'
                name='select_all'
                onChange={selectAllCheckboxes}
              />
            </th>
            <th>Company name</th>
            <th>FYE</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {entities ? (
            entities.map((item, i) => {
              return (
                <tr key={i}>
                  <td className='table-checkbox'>
                    {selection && (
                      <input
                        type='checkbox'
                        className='form-check-input'
                        name={item.company_uen}
                        checked={selection[item.company_uen]}
                        onChange={handleCheckBox}
                      />
                    )}
                  </td>
                  <td>
                    <Link to='/entity' state={{ ...item }}>
                      {item.company_name}
                    </Link>
                  </td>
                  <td>{item.fye}</td>
                  <td>
                    <div className={item.status_class}>{item.status}</div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={4}>No data available!</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className='flex-align-center self-center-align margin-top-24 gap-24'>
        <button className='button-large' onClick={handleSubmission}>
          Batch Process
        </button>
        <button className='button-large'>Batch Remind</button>
      </div>
    </Section>
  )
}

export default Index
