import { useContext } from 'react'
import { Navigate, Outlet, Link } from 'react-router-dom'
import { AuthContext } from '../store/authContext'

const HomeRoutes = () => {
  const { user } = useContext(AuthContext)
  console.log('home route')

  if (user) {
    return <Navigate to={'/'} />
  }

  return (
    <>
      <nav>
        <div className='navbar-wrapper'>
          <div className='logo-text'>CorpFast</div>
          <div className='flex-align-center gap-8'>
            <Link to='/signup' className='button'>Sign up</Link>
            <Link to='/login' className='button outline-button'>Log in</Link>
          </div>
        </div>
      </nav>
      <section className='gradient-bg'>
        <div className='section-wrapper ghost-white-bg relative-wrapper'>
          <Outlet />
        </div>
      </section>
    </>
  )
}

export default HomeRoutes
