import { useParams, useNavigate, useLocation, Navigate } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import AGM from '../components/AGM'
import CRA from '../components/CRA'
import CCS from '../components/CCS'
import { formatDate, convertTime } from '../helpers'
import Section from '../components/Section'

const Resolutions = () => {
  const { resotype } = useParams()
  let initialState = { document_type: resotype }
  let companies, companyText
  const location = useLocation()

  if (location.state) {
    if (Array.isArray(location.state)) {
      initialState = { ...initialState, selection: location.state }
      companyText = 'Companies'
      const allCompanyNames = []
      location.state.forEach((item) => {
        allCompanyNames.push(item.company_name)
      })
      companies = allCompanyNames.join(', ')
    } else {
      initialState = { ...initialState, ...location.state }
      companies = location.state.company_name
      companyText = 'Company'
    }
  }

  const [resolution, setResolution] = useState(initialState)
  const navigate = useNavigate()

  const sendToServer = useCallback(() => {
    fetch('/api/resolution', {
      method: 'POST',
      body: JSON.stringify(resolution),
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((result) => result.json())
      .then((data) => {
        navigate('/editor', { state: data })
      })
  }, [navigate, resolution])

  useEffect(() => {
    if (resotype === 'registration-of-corppass') {
      sendToServer()
    }
  }, [resotype, sendToServer])

  if (!location.state) {
    return <Navigate to='/' />
  }

  console.log('resolution', resolution)

  const handleInput = (event) => {
    let inputValue = event.target.value
    if (event.target.type === 'date') {
      inputValue = formatDate(event.target.value)
    }
    if (event.target.type === 'time') {
      inputValue = convertTime(event.target.value)
    }
    console.log(inputValue)
    setResolution((prev) => {
      return { ...prev, [event.target.name]: inputValue }
    })
  }

  let content
  switch (resotype) {
    case 'change-of-registered-address':
      content = <CRA handleInput={handleInput} />
      break
    case 'change-of-company-secretary':
      content = <CCS handleInput={handleInput} />
      break
    default:
      content = <AGM handleInput={handleInput} />
  }

  const handleSubmit = (event) => {
    event.preventDefault()

    console.log(resolution)

    sendToServer()
  }

  const resoTitle = resotype.replace(/-/g, ' ')
  return (
    <Section
      backButtonEnabled={true}
      backButtonText='Back'
      backButtonConfig={{ to: '/resolutions', state: location.state }}
    >
      <div className='max-width-500'>
        <h2 className='section-header margin-bottom-40 capitalize'>
          {resoTitle}
        </h2>
      </div>
      <div className='form-wrapper'>
        <form onSubmit={handleSubmit}>
          <p className='margin-bottom-40 dark-gray-bold'>
            {companyText}: {companies}
          </p>
          {resotype !== 'registration-of-corppass' ? (
            <>
              {content}
              <button className='button-large'>Submit</button>
            </>
          ) : (
            <p>Fetching documents...</p>
          )}
        </form>
      </div>
    </Section>
  )
}

export default Resolutions
