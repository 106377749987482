import { ReactComponent as GoogleLogo } from'../assets/google_logo.svg'
import { ReactComponent as FacebookLogo } from'../assets/facebook_logo.svg'
import { ReactComponent as LinkedinLogo } from'../assets/linkedin_logo.svg'
import { ReactComponent as TrianglesSVG } from'../assets/triangles.svg'
import { ReactComponent as SquaresSVG } from'../assets/squares.svg'

const Registration = (props) => {
  return (
    <>
      <div className='max-width-600'>
        <div className='grid columns-2-auto-1 gap-48'>
          {props.children}
          <div className='vertical-divider'></div>
          <div className='flex-vertical-start'>
            <h3 className='topic-header margin-bottom-24'>Continue with</h3>
            <button className='oauth-button'>
              <GoogleLogo /> Google
            </button>
            <button className='oauth-button'>
              <FacebookLogo /> Facebook
            </button>
            <button className='oauth-button'>
              <LinkedinLogo /> LinkedIn
            </button>
          </div>
        </div>
      </div>
      <TrianglesSVG style={{ position: 'absolute', right: 0 }} />
      <SquaresSVG style={{ position: 'absolute', bottom: 0, left: 0 }} />
    </>
  )
}

export default Registration
