import { useLocation } from 'react-router-dom'
import { Editor as RichTextEditor } from '@tinymce/tinymce-react'
import { useRef, createRef, useState } from 'react'
import Section from '../components/Section'

const Editor = () => {
  const location = useLocation()
  const [isLoading, setIsLoading] = useState(true)
  let stateReso = location.state.reso

  if (!Array.isArray(stateReso)) {
    stateReso = [stateReso]
  }
  const ref = useRef(stateReso.map(() => createRef()))

  return (
    <Section backButtonEnabled={true}>
      {isLoading && <p className='margin-top-40'>Fetching documents...</p>}
      {stateReso.map((eachReso, i) => {
        return (
          <div key={i}>
            <RichTextEditor
              apiKey='4qxhe0q7l3wt26d7b2zhv4rwk8gv96keh96ymiwxq3bip5cv'
              onInit={(evt, editor) => {
                console.log('editor initialised')
                setIsLoading(false)
                return (ref.current[i] = editor)
              }}
              initialValue={eachReso}
              init={{
                height: 500,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'wordcount',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            />
            {!isLoading && <button
              title='This feature is not yet available'
              className='margin-bottom-40 margin-top-24'
              disabled
            >
              Request e-signature
            </button>}
          </div>
        )
      })}
    </Section>
  )
}

export default Editor
