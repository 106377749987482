import Section from '../components/Section'
import { ReactComponent as Edit } from '../assets/edit.svg'

const EditCompany = () => {
  const rates = [
    {
      service: 'Annual corporate secretary fees',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
      custom: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1100
        </div>
      ),
    },
    {
      service: 'Notice of Annual General Meeting',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
      custom: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 800
        </div>
      ),
    },
    {
      service: 'Annual financial statements',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
      custom: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 900
        </div>
      ),
    },
    {
      service: 'Shareholder resolution',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
      custom: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 600
        </div>
      ),
    },
    {
      service: 'Registration of CORPPASS',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
      custom: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 800
        </div>
      ),
    },
    {
      service: 'Change of registered address',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
      custom: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
    },
    {
      service: 'Change of company secretary',
      price: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1000
        </div>
      ),
      custom: (
        <div className='royal-blue-text'>
          <span className='font-weight-600'>S$</span> 1200
        </div>
      ),
    },
  ]
  return (
    <Section backButtonEnabled={true}>
      <h2 className='section-header margin-bottom-32'>Edit company details</h2>
      <div className='form-wrapper margin-bottom-32'>
        <h3 className='topic-header blue-text margin-bottom-24'>
          Information <br />{' '}
          <span className='font-weight-700'>ABCD Company</span>
        </h3>
        <div className='max-width-500'>
          <div className='grid columns-auto-1 gap-24'>
            <label className='dark-gray-bold'>Business name:</label>
            <input
              type='text'
              className='input no-margin-bottom'
              defaultValue='ABCD Pte ltd'
              placeholder='Enter business name'
            />
            <label className='dark-gray-bold'>Incorporated:</label>
            <input
              type='date'
              defaultValue='12/12/2022'
              className='form-control input no-margin-bottom'
            />
            <label className='dark-gray-bold'>Company UEN:</label>
            <input
              type='text'
              defaultValue='F9876123G'
              className='form-control input no-margin-bottom'
              placeholder='Enter company UEN'
            />
            <label className='dark-gray-bold'>Registered address:</label>
            <input
              type='text'
              className='form-control input no-margin-bottom'
              defaultValue='Singapore'
              placeholder='Enter address'
            />
            <label className='dark-gray-bold'>Registed postal code:</label>
            <input
              type='text'
              defaultValue='567234'
              className='form-control input no-margin-bottom'
              placeholder='234432'
            />
          </div>
        </div>
      </div>
      <div className='max-width-880 margin-bottom-32'>
        <div className='card no-bottom-radius padding-bottom-16'>
          <div className='flex-space-between align-center'>
            <h3 className='topic-header blue-text no-margin-bottom'>
              Directors
            </h3>
            <button className='button-large' id='add_director'>
              + Add new director
            </button>
          </div>
        </div>
        <table className='table margin-bottom-32'>
          <thead>
            <tr>
              <th className='table-checkbox'>Director name</th>
              <th className='table-checkbox'>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='table-checkbox'>Full name of the Director</td>
              <td>12/12/2022</td>
              <td className='svg-gray'>
                <Edit />
              </td>
            </tr>
            <tr>
              <td className='table-checkbox'>Full name of the Director</td>
              <td>12/12/2022</td>
              <td className='svg-gray'>
                <Edit />
              </td>
            </tr>
            <tr>
              <td className='table-checkbox'>Full name of the Director</td>
              <td>12/12/2022</td>
              <td className='svg-gray'>
                <Edit />
              </td>
            </tr>
            <tr>
              <td className='table-checkbox'>Full name of the Director</td>
              <td>12/12/2022</td>
              <td className='svg-gray'>
                <Edit />
              </td>
            </tr>
          </tbody>
        </table>

        <div className='card no-bottom-radius padding-bottom-16'>
          <div className='flex-space-between align-center'>
            <h3 className='topic-header blue-text no-margin-bottom'>
              Shareholders
            </h3>
            <button className='button-large' id='add_shareholder'>
              + Add new shareholder
            </button>
          </div>
        </div>
        <table className='table margin-bottom-32'>
          <thead>
            <tr>
              <th className='table-checkbox'>Shareholder name</th>
              <th className='table-checkbox'>Date</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className='table-checkbox'>Full name of the Shareholder</td>
              <td>12/12/2022</td>
              <td className='svg-gray'>
                <Edit />
              </td>
            </tr>
            <tr>
              <td className='table-checkbox'>Full name of the Shareholder</td>
              <td>12/12/2022</td>
              <td className='svg-gray'>
                <Edit />
              </td>
            </tr>
            <tr>
              <td className='table-checkbox'>Full name of the Shareholder</td>
              <td>12/12/2022</td>
              <td className='svg-gray'>
                <Edit />
              </td>
            </tr>
            <tr>
              <td className='table-checkbox'>Full name of the Shareholder</td>
              <td>12/12/2022</td>
              <td className='svg-gray'>
                <Edit />
              </td>
            </tr>
          </tbody>
        </table>
        <div className='card no-bottom-radius padding-bottom-16 width-100'>
          <div className='flex-space-between align-center'>
            <h3 className='topic-header blue-text no-margin-bottom'>
              Custom rates
            </h3>
            <button className='button-large' id='add_director'>
              + Add new rate
            </button>
          </div>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th className='table-checkbox'>Service</th>
              <th>Standard rates</th>
              <th>Custom rates</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {rates ? (
              rates.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className='table-checkbox'>{item.service}</td>
                    <td>{item.price}</td>
                    <td>{item.custom}</td>
                    <td className='svg-blue'>
                      <Edit />
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={4}>No data available!</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <div className='max-width-500'>
        <div className='form-wrapper margin-bottom-32'>
          <h3 className='topic-header blue-text margin-bottom-32'>
            Corporate Secretary
          </h3>
          <div className='max-width-300 margin-bottom-16'>
            <label className='dark-gray-bold margin-bottom-8'>
              Corporate Secretary
            </label>
            <div className='form-group'>
              <input className='input' type='text' />
              <div className='form-group-svg svg-gray'>
                <Edit />
              </div>
            </div>
          </div>
          <div className='max-width-300 margin-bottom-16'>
            <label className='dark-gray-bold margin-bottom-8'>Phone</label>
            <div className='form-group'>
              <input className='input' type='text' />
              <div className='form-group-svg svg-gray'>
                <Edit />
              </div>
            </div>
          </div>
          <div className='max-width-300 margin-bottom-16'>
            <label className='dark-gray-bold margin-bottom-8'>Email</label>
            <div className='form-group'>
              <input className='input' type='text' />
              <div className='form-group-svg svg-gray'>
                <Edit />
              </div>
            </div>
          </div>
        </div>

        <div className='form-wrapper margin-bottom-32'>
          <h3 className='topic-header blue-text margin-bottom-32'>
            Point of Contact
          </h3>
          <div className='max-width-300 margin-bottom-16'>
            <label className='dark-gray-bold margin-bottom-8'>
              Corporate Secretary
            </label>
            <div className='form-group'>
              <input className='input' type='text' />
              <div className='form-group-svg svg-gray'>
                <Edit />
              </div>
            </div>
          </div>
          <div className='max-width-300 margin-bottom-16'>
            <label className='dark-gray-bold margin-bottom-8'>Phone</label>
            <div className='form-group'>
              <input className='input' type='text' />
              <div className='form-group-svg svg-gray'>
                <Edit />
              </div>
            </div>
          </div>
          <div className='max-width-300 margin-bottom-16'>
            <label className='dark-gray-bold margin-bottom-8'>Email</label>
            <div className='form-group'>
              <input className='input' type='text' />
              <div className='form-group-svg svg-gray'>
                <Edit />
              </div>
            </div>
          </div>
        </div>

        <div className='form-wrapper'>
          <h3 className='topic-header blue-text margin-bottom-32'>SSIC Code</h3>
          <div className='grid columns-auto-1'>
            <label className='dark-gray-bold margin-bottom-8'>SSIC Code</label>
            <div className='form-group'>
              <input className='input' type='text' />
              <div className='form-group-svg svg-gray'>
                <Edit />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default EditCompany
