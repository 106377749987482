import Input from '../components/Input'

const CCS = ({ handleInput }) => {
  return (
    <>
      <Input
        label='Resigning secretary name'
        details={{ type: 'text', name: 'pre_secretary' }}
        onchange={handleInput}
      />
      <Input
        label='Resigning secretary address line 1'
        details={{ type: 'text', name: 'sec_add_line1' }}
        onchange={handleInput}
      />
      <Input
        label='Resigning secretary address line 2'
        details={{ type: 'text', name: 'sec_add_line2' }}
        onchange={handleInput}
      />
      <Input
        label='Resigning secretary address postal code'
        details={{ type: 'text', name: 'sec_add_pc' }}
        onchange={handleInput}
      />
      <Input
        label='Resignation date'
        details={{ type: 'date', name: 'resig_date' }}
        onchange={handleInput}
      />
      <Input
        label='New secretary name'
        details={{ type: 'text', name: 'new_secretary' }}
        onchange={handleInput}
      />
    </>
  )
}

export default CCS
