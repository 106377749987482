import { useEffect, useState, useMemo } from 'react'
import Section from '../components/Section'

const Invoices = () => {
  const demo = useMemo(
    () => [
      {
        id: 1,
        company_name: 'ABD Pte Ltd',
        company_uen: 'F9876123G',
        reg_add_line1: 'Jalan Sultan',
        reg_add_line2: 'Left Road',
        fye: '31/5/2022',
        status: 'Outstanding',
        reg_add_pc: '567234',
        status_class: 'status-tag amber',
        directors: {
          1: 'David Thomas',
          2: 'Brian Hernandez',
          3: 'Geraldine Enos',
          4: 'Matthew Smith',
        },
      },
      {
        id: 2,
        company_name: 'Company B Pte Ltd',
        company_uen: 'L0946324J',
        reg_add_line1: 'Mohd Sultan',
        fye: '20/8/2020',
        reg_add_line2: 'Right Road',
        status: 'Paid',
        status_class: 'status-tag',
        reg_add_pc: '739274',
        directors: {
          1: 'Cole Palmer',
          2: 'Erling Cruise',
          3: 'Paul Walker',
          4: 'Stewart Robert',
        },
      },
      {
        id: 3,
        company_name: 'Company C Pte Ltd',
        company_uen: 'L0946324J',
        reg_add_line1: 'Mohd Sultan',
        fye: '03/8/2024',
        reg_add_line2: 'Right Road',
        status: 'Overdue',
        status_class: 'status-tag red',
        reg_add_pc: '739274',
        directors: {
          1: 'Cole Palmer',
          2: 'Erling Cruise',
          3: 'Paul Walker',
          4: 'Stewart Robert',
        },
      },
    ],
    []
  )
  const [entities] = useState(demo)
  const [selection, setSelection] = useState()

  useEffect(() => {
    demo.forEach((item) => {
      setSelection((prev) => {
        return { ...prev, [item.company_uen]: false }
      })
    })
  }, [demo])

  console.log(selection)

  const selectAllCheckboxes = (event) => {
    for (let key in selection) {
      setSelection((prev) => {
        return { ...prev, [key]: event.target.checked }
      })
    }
  }

  const handleCheckBox = (event) => {
    setSelection((prev) => {
      return { ...prev, [event.target.name]: event.target.checked }
    })
  }

  return (
    <Section backButtonEnabled={true}>
      <div className='flex-space-between margin-bottom-32'>
        <h2 className='section-header'>Invoices</h2>
      </div>
      <table className='table'>
        <thead>
          <tr>
            <th className='table-checkbox border-radius-top-left'>
              <input
                type='checkbox'
                className='form-check-input'
                name='select_all'
                onChange={selectAllCheckboxes}
              />
            </th>
            <th>Date</th>
            <th>Invoice number</th>
            <th>Company name</th>
            <th className='border-radius-top-right'>Status</th>
          </tr>
        </thead>
        <tbody>
          {entities ? (
            entities.map((item, i) => {
              return (
                <tr key={i}>
                  <td className='table-checkbox'>
                    {selection && (
                      <input
                        type='checkbox'
                        className='form-check-input'
                        name={item.company_uen}
                        checked={selection[item.company_uen]}
                        onChange={handleCheckBox}
                      />
                    )}
                  </td>
                  <td>{item.fye}</td>
                  <td>12022BOO78</td>
                  <td>{item.company_name}</td>
                  <td>
                    <div className={item.status_class}>{item.status}</div>
                  </td>
                </tr>
              )
            })
          ) : (
            <tr>
              <td colSpan={4}>No data available!</td>
            </tr>
          )}
        </tbody>
      </table>
      <div className='pagination-wrapper'>
        <div className='limit-wrapper'>
          <span className='dark-gray-text'>Showing</span>
          <select className='form-select'>
            <option>10</option>
            <option>20</option>
            <option>50</option>
            <option>100</option>
          </select>
          Out of 55
        </div>
        <div className='pagination-buttons'>
          <button>Prev</button>
          <button>01</button>
          <button>02</button>
          <button>03</button>
          <button>04</button>
          <button>05</button>
          <button>Next</button>
        </div>
      </div>
    </Section>
  )
}

export default Invoices
