import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { AuthContextProvider } from './store/authContext'
import { BrowserRouter } from 'react-router-dom'

//Poppins font
import './fonts/Poppins-Bold.woff'
import './fonts/Poppins-Regular.woff'
import './fonts/Poppins-Medium.woff'
import './fonts/Poppins-SemiBold.woff'
import './fonts/Poppins-Light.woff'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <BrowserRouter>
    <AuthContextProvider>
      <App />
    </AuthContextProvider>
  </BrowserRouter>
)
