import Input from '../components/Input'

const AGM = ({handleInput}) => {
  return (
    <>
      <Input
        label='Financial year end date'
        details={{ type: 'date', name: 'fye_date' }}
        onchange={handleInput}
      />
      <Input
        label='Annual general meeting date'
        details={{ type: 'date', name: 'agm_date' }}
        onchange={handleInput}
      />
      <Input
        label='Annual general meeting time'
        details={{ type: 'time', name: 'agm_time' }}
        onchange={handleInput}
      />
    </>
  )
}

export default AGM
