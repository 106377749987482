import { createContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const AuthContext = createContext()

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user')) || null
  )
  const navigate = useNavigate()

  const login = (loggedInUser) => {
    localStorage.setItem('user', JSON.stringify(loggedInUser))
    navigate('/', { replace: true })
    setUser(loggedInUser)
  }

  const logout = () => {
    localStorage.removeItem('user')
    setUser(null)
    navigate('/login')
  }

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  )
}
