import { useState } from 'react'
import Section from '../components/Section'
import { ReactComponent as Edit } from '../assets/edit.svg'
import { ReactComponent as Close } from '../assets/close_icon.svg'
import Modal from 'react-bootstrap/Modal'

const AddCompany = () => {
  const [modal, setModal] = useState({
    isOpen: false,
    subject: 'director',
  })

  const handleClose = () => {
    setModal((prev) => {
      return { ...prev, isOpen: false }
    })
  }
  const handleShow = (event) => {
    console.log(event.target.id)
    const modalSubject = event.target.id.replace('add_', '')
    setModal({ isOpen: true, subject: modalSubject })
  }

  return (
    <>
      <Section backButtonEnabled={true}>
        <h2 className='section-header margin-bottom-32'>Add company</h2>
        <div className='form-wrapper margin-bottom-32'>
          <h3 className='topic-header blue-text margin-bottom-24'>
            Information
          </h3>
          <div className='max-width-500'>
            <div className='grid columns-auto-1 gap-24'>
              <label className='dark-gray-bold'>Business name:</label>
              <input
                type='text'
                className='input no-margin-bottom'
                placeholder='Enter business name'
              />
              <label className='dark-gray-bold'>Incorporated:</label>
              <input
                type='date'
                className='form-control input no-margin-bottom'
              />
              <label className='dark-gray-bold'>Company UEN:</label>
              <input
                type='text'
                className='form-control input no-margin-bottom'
                placeholder='Enter company UEN'
              />
              <label className='dark-gray-bold'>Registered address:</label>
              <input
                type='text'
                className='form-control input no-margin-bottom'
                placeholder='Enter address'
              />
              <label className='dark-gray-bold'>Registed postal code:</label>
              <input
                type='text'
                className='form-control input no-margin-bottom'
                placeholder='234432'
              />
            </div>
          </div>
        </div>
        <div className='max-width-880 margin-bottom-32'>
          <div className='card no-bottom-radius padding-bottom-16'>
            <div className='flex-space-between align-center'>
              <h3 className='topic-header blue-text no-margin-bottom'>
                Directors
              </h3>
              <button
                className='button-large'
                id='add_director'
                onClick={handleShow}
              >
                + Add new director
              </button>
            </div>
          </div>
          <table className='table margin-bottom-32'>
            <thead>
              <tr>
                <th className='table-checkbox'>Director name</th>
                <th className='table-checkbox'>Date</th>
              </tr>
            </thead>
          </table>

          <div className='card no-bottom-radius padding-bottom-16'>
            <div className='flex-space-between align-center'>
              <h3 className='topic-header blue-text no-margin-bottom'>
                Shareholders
              </h3>
              <button
                className='button-large'
                id='add_shareholder'
                onClick={handleShow}
              >
                + Add new shareholder
              </button>
            </div>
          </div>
          <table className='table'>
            <thead>
              <tr>
                <th className='table-checkbox'>Shareholder name</th>
                <th className='table-checkbox'>Date</th>
              </tr>
            </thead>
          </table>
        </div>

        <div className='max-width-500'>
          <div className='form-wrapper margin-bottom-32'>
            <h3 className='topic-header blue-text margin-bottom-32'>
              Corporate Secretary
            </h3>
            <div className='max-width-300 margin-bottom-16'>
              <label className='dark-gray-bold margin-bottom-8'>
                Corporate Secretary
              </label>
              <div className='form-group'>
                <input className='input' type='text' />
                <div className='form-group-svg svg-gray'>
                  <Edit />
                </div>
              </div>
            </div>
            <div className='max-width-300 margin-bottom-16'>
              <label className='dark-gray-bold margin-bottom-8'>Phone</label>
              <div className='form-group'>
                <input className='input' type='text' />
                <div className='form-group-svg svg-gray'>
                  <Edit />
                </div>
              </div>
            </div>
            <div className='max-width-300 margin-bottom-16'>
              <label className='dark-gray-bold margin-bottom-8'>Email</label>
              <div className='form-group'>
                <input className='input' type='text' />
                <div className='form-group-svg svg-gray'>
                  <Edit />
                </div>
              </div>
            </div>
          </div>

          <div className='form-wrapper margin-bottom-32'>
            <h3 className='topic-header blue-text margin-bottom-32'>
              Point of Contact
            </h3>
            <div className='max-width-300 margin-bottom-16'>
              <label className='dark-gray-bold margin-bottom-8'>
                Corporate Secretary
              </label>
              <div className='form-group'>
                <input className='input' type='text' />
                <div className='form-group-svg svg-gray'>
                  <Edit />
                </div>
              </div>
            </div>
            <div className='max-width-300 margin-bottom-16'>
              <label className='dark-gray-bold margin-bottom-8'>Phone</label>
              <div className='form-group'>
                <input className='input' type='text' />
                <div className='form-group-svg svg-gray'>
                  <Edit />
                </div>
              </div>
            </div>
            <div className='max-width-300 margin-bottom-16'>
              <label className='dark-gray-bold margin-bottom-8'>Email</label>
              <div className='form-group'>
                <input className='input' type='text' />
                <div className='form-group-svg svg-gray'>
                  <Edit />
                </div>
              </div>
            </div>
          </div>

          <div className='form-wrapper'>
            <h3 className='topic-header blue-text margin-bottom-32'>
              SSIC Code
            </h3>
            <div className='grid columns-auto-1'>
              <label className='dark-gray-bold margin-bottom-8'>
                SSIC Code
              </label>
              <div className='form-group'>
                <input className='input' type='text' />
                <div className='form-group-svg svg-gray'>
                  <Edit />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Section>
      <Modal show={modal.isOpen} onHide={handleClose}>
        <div onClick={handleClose} className='close-modal'>
          <Close />
        </div>
        <Modal.Body>
          <h3 className='topic-header blue-text margin-bottom-32'>
            Add new {modal.subject}
          </h3>
          <label className='dark-gray-bold margin-bottom-8'>Full name</label>
          <input
            className='input margin-bottom-32'
            type='text'
            placeholder={`Enter name of ${modal.subject}`}
          />
          <button className='button-large'>+Add {modal.subject}</button>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AddCompany
