import Section from '../components/Section'
import { ReactComponent as Edit } from '../assets/edit.svg'

const CustomRates = () => {
    const rates = [
        {
          service: 'Annual corporate secretary fees',
          price: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 1000
            </div>
          ),
          custom: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 1100
            </div>
          ),
        },
        {
          service: 'Notice of Annual General Meeting',
          price: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 1000
            </div>
          ),
          custom: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 800
            </div>
          ),
        },
        {
          service: 'Annual financial statements',
          price: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 1000
            </div>
          ),
          custom: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 900
            </div>
          ),
        },
        {
          service: 'Shareholder resolution',
          price: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 1000
            </div>
          ),
          custom: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 600
            </div>
          ),
        },
        {
          service: 'Registration of CORPPASS',
          price: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 1000
            </div>
          ),
          custom: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 800
            </div>
          ),
        },
        {
          service: 'Change of registered address',
          price: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 1000
            </div>
          ),
          custom: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 1000
            </div>
          ),
        },
        {
          service: 'Change of company secretary',
          price: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 1000
            </div>
          ),
          custom: (
            <div className='royal-blue-text'>
              <span className='font-weight-600'>S$</span> 1200
            </div>
          ),
        },
      ]

  return (
    <Section backButtonEnabled={true}>
      <div className='max-width-500 margin-bottom-32'>
        <h2 className='section-header'><span className='dark-text'>Custom rates for:</span> ABCD Company</h2>
      </div>
      <div className='card no-bottom-radius padding-bottom-16 width-100'>
          <div className='flex-space-between align-center'>
            <h3 className='topic-header blue-text no-margin-bottom'>
              Custom rates
            </h3>
            <button className='button-large' id='add_director'>
              + Add new rate
            </button>
          </div>
        </div>
        <table className='table'>
          <thead>
            <tr>
              <th className='table-checkbox'>Service</th>
              <th>Standard rates</th>
              <th>Custom rates</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {rates ? (
              rates.map((item, i) => {
                return (
                  <tr key={i}>
                    <td className='table-checkbox'>{item.service}</td>
                    <td>{item.price}</td>
                    <td>{item.custom}</td>
                    <td className='svg-blue'>
                      <Edit />
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan={4}>No data available!</td>
              </tr>
            )}
          </tbody>
        </table>
    </Section>
  )
}

export default CustomRates
