import { Routes, Route } from 'react-router-dom'
import Index from './pages/Index'
import Resolutions from './pages/Resolutions'
import Editor from './pages/Editor'
import Login from './pages/Login'
import ProtectedRoutes from './components/ProtectedRoutes'
import HomeRoutes from './components/HomeRoutes'
import ResolutionCards from './pages/ResolutionCards'
import Company from './pages/Company'
import Invoices from './pages/Invoices'
import AddCompany from './pages/AddCompany'
import Register from './pages/Register'
import PasswordReset from './pages/PasswordReset'
import Rates from './pages/Rates'
import CustomRates from './pages/CustomRates'
import EditCompany from './pages/EditCompany'

function App() {
  return (
    <Routes>
      <Route path='/' element={<ProtectedRoutes />}>
        <Route path='/' element={<Index />} />
        <Route path='/resolutions' element={<ResolutionCards />} />
        <Route path='/resolutions/:resotype' element={<Resolutions />} />
        <Route path='/editor' element={<Editor />} />
        <Route path='/entity' element={<Company />} />
        <Route path='/entity/new' element={<AddCompany />} />
        <Route path='/invoices' element={<Invoices />} />
        <Route path='/rates' element={<Rates />} />
        <Route path='/custom-rates' element={<CustomRates />} />
        <Route path='/entity/edit' element={<EditCompany />} />
      </Route>
      <Route path='/' element={<HomeRoutes />}>
        <Route path='/login' element={<Login />} />
        <Route path='/signup' element={<Register />} />
        <Route path='/reset-password' element={<PasswordReset />} />
      </Route>
    </Routes>
  )
}

export default App
