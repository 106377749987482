import { Navigate, useLocation, Link } from 'react-router-dom'
import Section from '../components/Section'
import { ReactComponent as Edit } from '../assets/edit.svg'
import { ReactComponent as Document } from '../assets/document_icon.svg'

const Company = () => {
  const location = useLocation()

  console.log(location.state)

  if (!location.state) {
    return <Navigate to='/' />
  }

  return (
    <Section backButtonEnabled={true}>
      <div className='flex-space-between margin-bottom-32'>
        <h2 className='section-header'>{location.state.company_name}</h2>
        <Link className='button button-large' to='/entity/edit'>
          <Edit /> Edit company details
        </Link>
      </div>

      <div className='grid gap-24'>
        <div className='card'>
          <div className='grid columns-1-auto'>
            <h3 className='topic-header blue-text no-margin-bottom'>
              Information
            </h3>
            <div className='justify-self-right'>
              <Document />
            </div>
            <div className='grid'>
              <div className='dark-gray-bold'>Incorporated:</div>
              <div>12/12/2022</div>
              <div className='dark-gray-bold'>Company UEN:</div>
              <div>{location.state.company_uen}</div>
              <div className='dark-gray-bold'>Registered address:</div>
              <div>
                {location.state.reg_add_line1} {location.state.reg_add_line2}
              </div>
              <div className='dark-gray-bold'>Registered postal code:</div>
              <div>{location.state.reg_add_pc}</div>
            </div>
            <Link
              to='/resolutions'
              className='button outline-button blue align-self-bottom'
              state={{...location.state}}
            >
              Generate documents
            </Link>
          </div>
        </div>
        <div className='card'>
          <div className='max-width-380'>
            <div className='grid columns-1-auto'>
              <h3 className='topic-header blue-text no-margin-bottom'>
                Fee Summary
              </h3>
              <div className='font-weight-600 blue-text'>S$560,000</div>
              <div className='dark-gray-bold'>
                Annual Corporate Secretary Fee:
              </div>
              <div className='justify-self-right'>S$200</div>
              <div className='dark-gray-bold'>Due Date:</div>
              <div className='justify-self-right'>12/01/2022</div>
              <div className='dark-gray-bold'>Payment Status:</div>
              <div className='justify-self-right'>OK</div>
            </div>
          </div>

          <div className='flex-align-center margin-top-24'>
            <Link className='button outline-button blue align-self-bottom'>
              Send invoice reminder
            </Link>
            <Link to='/custom-rates' className='text-link'>Set custom rates</Link>
          </div>
        </div>
      </div>

      <div className='grid gap-24 margin-top-24'>
        <div>
          <div className='list-header'>
            <h3 className='topic-header blue-text'>Directors</h3>
          </div>
          <ul className='list-group list-group-flush'>
            {Object.keys(location.state.directors).map((director, i) => {
              return (
                <li key={i} className='list-group-item'>
                  {location.state.directors[director]}
                </li>
              )
            })}
          </ul>
        </div>
        <div>
          <div className='list-header'>
            <h3 className='topic-header blue-text'>Shareholders</h3>
          </div>
          <ul className='list-group list-group-flush'>
            {Object.keys(location.state.directors).map((director, i) => {
              return (
                <li key={i} className='list-group-item'>
                  {location.state.directors[director]}
                </li>
              )
            })}
          </ul>
        </div>
      </div>

      <div className='grid columns-3 margin-top-24'>
        <div className='card'>
          <h3 className='topic-header blue-text'>Corporate Secretary</h3>
          <div className='flex-vertical-start gap-8'>
            <p>Full name of the Corporate Secretary</p>
            <p>+65 55 55 55 55</p>
            <p>CorporateSecretary.gmail.com</p>
          </div>
        </div>
        <div className='card'>
          <h3 className='topic-header blue-text'>Point of Contact</h3>
          <div className='flex-vertical-start gap-8'>
            <p>Full name of the Corporate Secretary</p>
            <p>+65 55 55 55 55</p>
            <p>CorporateSecretary.gmail.com</p>
          </div>
        </div>
        <div className='card'>
          <div className='grid columns-2-1'>
            <h3 className='topic-header blue-text no-margin-bottom'>
              SSIC Code
            </h3>
            <div className='justify-self-right'>
              <Document />
            </div>
            <p>653432</p>
          </div>
        </div>
      </div>

      <div className='flex-vertical-start margin-bottom-40'></div>
    </Section>
  )
}

export default Company
