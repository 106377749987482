import { Link } from 'react-router-dom'
import { ReactComponent as Number } from '../assets/number_wrapper.svg'
import { ReactComponent as Triangles } from '../assets/triangles.svg'

const ResolutionCard = (props) => {
  return (
    <Link to={`/resolutions/${props.url}`} state={props.company_details} className='button-card'>
      <div className='reso-number margin-bottom-24'>
        <Number />
        <div className='relative-wrapper'>{props.resoNumber}</div>
      </div>
      <div className='max-width-270 margin-bottom-40' style={{ zIndex: 1 }}><h3 className='topic-header blue-text'>{props.name}</h3></div>
      <div className='text-link' style={{ zIndex: 1 }}>Generate document</div>
      <Triangles style={{ position: 'absolute', right: 0 }} />
    </Link>
  )
}

export default ResolutionCard
