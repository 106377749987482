import { Navigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import Section from '../components/Section'
import ResolutionCard from '../components/ResolutionCard'

const ResolutionCards = () => {
  const location = useLocation()

  console.log(location)

  if (!location.state) {
    return <Navigate to='/' />
  }

  return (
    <Section
      backButtonEnabled={true}
      backButtonText={'Back'}
      backButtonConfig={{ to: '/entity', state: location.state }}
    >
      <div className='max-width-445 margin-bottom-32'>
        <h2 className='section-header'>
          Select Resolution for {location.state.company_name}
        </h2>
      </div>
      <div className='grid columns-3'>
        <ResolutionCard
          resoNumber='1'
          url='annual-financial-statements'
          name='Annual financial statements'
          company_details={location.state}
        />
        <ResolutionCard
          resoNumber='2'
          url='change-of-company-secretary'
          name='Change of company secretary'
          company_details={location.state}
        />
        <ResolutionCard
          resoNumber='3'
          url='change-of-registered-address'
          name='Change of registered address'
          company_details={location.state}
        />
        <ResolutionCard
          resoNumber='4'
          url='registration-of-corppass'
          name='Registration of CORPPASS'
          company_details={location.state}
        />
        <ResolutionCard
          resoNumber='5'
          url='notice-of-annual-general-meeting'
          name='Notice of annual general meeting'
          company_details={location.state}
        />
        <ResolutionCard
          resoNumber='6'
          url='shareholder-resolution'
          name='Shareholder resolution'
          company_details={location.state}
        />
      </div>
    </Section>
  )
}

export default ResolutionCards
